import * as React from 'react';
import {
    Form, Formik, FormikValues
} from 'formik';
import EmailInput from '../../formElements/EmailInput/EmailInput';
import PasswordInput from '../../formElements/PasswordInput/PasswordInput';
import FormSubmit from '../../formElements/FormSubmit/FormSubmit';
import FormRequestError from '../../formElements/FormRequestError/FormRequestError';
import {
    useEffect,
    useState
} from 'react';
import {
    fetchUser, login
} from '../../../api/user';
import {
    getAuthUser,
    setAuthUser
} from '../../../helpers/user';
import {
    connect
} from 'react-redux';
import {
    navigate
} from 'gatsby';
import CustomButton from '../../CustomButton/CustomButton';
import PasswordReset from '../PasswordReset/PasswordReset';

// @ts-ignore
import * as styles from './UserLogin.module.css';

interface IFormErrors {
    email: string,
    password: string
}

const attemptLogin = function (
    values: FormikValues,
    // eslint-disable-next-line no-unused-vars
    setAuthenticatedUser: ({
    // eslint-disable-next-line no-unused-vars
        user: IUser,
    }) => void,
    // eslint-disable-next-line no-unused-vars
    setError: (value: (((prevState: string) => string) | string)) => void,
    // eslint-disable-next-line no-unused-vars
    setSubmitting: (isSubmitting: boolean) => void
) {
    (async () => {
        const {
            success, errors,
        } = await login(
            values.email,
            values.password
        );
        if (success) {
            setTimeout(
                async () => {
                    const user = await fetchUser();
                    if (user) {
                        setAuthUser(user);
                        setAuthenticatedUser({
                            user: user,
                        });
                    } else {
                        setError('Server error');
                    }
                },
                250
            );
        } else {
            if (errors && errors.verification) {
                setError('Die E-Mail Adresse wurde noch nicht bestätigt.');
            } else {
                setError('Die Anmeldedaten sind ungültig');
            }
        }
        setSubmitting(false);
    })();
};

const UserLogin = ({
    authenticatedUser,
    setAuthenticatedUser,
}) => {
    const phase = process.env.GATSBY_PHASE;
    const [error, setError,] = useState(null as null | string);
    const [showForgotPassword, setShowForgotPassword,] = useState(false);
    const [emailSent, setEmailSent,] = useState(false);

    // is user is already logged in return to front
    useEffect(
        () => {
            if (authenticatedUser) {
                if ('jury' === phase) {
                    navigate('/jury/welcome');
                } else {
                    navigate('/');
                }
            } else {
                const user = getAuthUser();
                if (user) {
                    setAuthenticatedUser(user);
                }
            }
        },
        [authenticatedUser,]
    );

    if(emailSent) {
        return <main className="grid-2--mobile grid-8--tablet grid-12--desktop">
            <div>
                <br/>
                <p>Eine E-Mail mit einem Link zum Passwort zurücksetzen wurde an Ihre E-Mail Adresse verschickt.</p>
                <p>Bitte prüfen Sie auch Ihren Spam-Ordner.</p>
            </div>
        </main>;
    }

    if (showForgotPassword) {
        return <main className="grid-2--mobile grid-8--tablet grid-12--desktop"><PasswordReset
            setShowForgotPassword={setShowForgotPassword}
            emailSentCallback={() => {
                setEmailSent(true);
            }}/></main>;
    }

    return <Formik
        initialValues={{
            email: '',
            password: '',
        }}
        validate={values => {
            const errors = {} as IFormErrors;
            if (!values.password) {
                errors.password = 'Bitte füllen Sie das Feld aus';
            }
            if (!values.email) {
                errors.email = 'Bitte füllen Sie das Feld aus';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Ungültige E-Mail Adresse';
            }
            return errors;
        }}
        onSubmit={(
            values, {
                setSubmitting,
            }
        ) => {
            return attemptLogin(
                values,
                setAuthenticatedUser,
                setError,
                setSubmitting
            );
        }}
    >
        {({
            isSubmitting,
            values,
        }) => {
            return (
                <Form
                    className={isSubmitting ? '-is-submitting   flex grid-2--mobile grid-8--tablet grid-12--desktop' : 'grid-2--mobile grid-8--tablet grid-12--desktop'}
                >
                    <EmailInput
                        label="E-Mail"
                        value={values.email}
                        name="email"
                        testId="login__email-input"
                    />
                    <PasswordInput
                        label="Passwort"
                        value={values.password}
                        name="password"
                        testId="login__password-input"
                    />
                    <CustomButton
                        style="link"
                        className={styles.forgotPassword}
                        label="Passwort vergessen?"
                        // additionalClassName="linkWithArrow"
                        onClick={() => {
                            setShowForgotPassword(true);
                        }}
                    />
                    {
                        error && <FormRequestError error={error}/>
                    }
                    <FormSubmit
                        disabled={isSubmitting}
                        label="Login"
                    />
                    <br/>
                    <br/>
                </Form>
            );
        }}
    </Formik>;
};

const mapStateToProps = ({
    authenticatedUser,
}) => {
    return {
        authenticatedUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthenticatedUser: ({
            user,
        }) => {
            return dispatch({
                type: 'SET_AUTHENTICATED_USER',
                payload: {
                    authenticatedUser: user,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLogin);
