import * as React from 'react';

// @ts-ignore
import * as styles from './FormRequestError.module.css';

interface IFormRequestError {
    error: string
}

const FormRequestError = ({
    error,
}: IFormRequestError) => {
    return <div className={styles.container}>{error}</div>;
};

export default FormRequestError;
