import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
import UserLogin from '../../components/forms/UserLogin/UserLogin';
import {
    connect
} from 'react-redux';


const JuryLoginPage = () => {
    return <DefaultPage
        phase={process.env.GATSBY_PHASE}
        hideHelpMenu>
        <UserLogin/>
    </DefaultPage>;
};

const mapStateToProps = ({
    activeLoginTab,
}) => {
    return {
        activeLoginTab,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthenticatedUser: ({
            user,
        }) => {
            return dispatch({
                type: 'SET_AUTHENTICATED_USER',
                payload: {
                    authenticatedUser: user,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JuryLoginPage);
